import { Component, OnInit, ViewChild } from '@angular/core';
import { SideNavService } from './side-nav.service';
import { UserService } from '../../../services/user/user.service';
import { LanguageService } from '../../../services/language/language.service';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { EnvironmentService } from '../../../services/environment.service';
import { AccordionDirective } from '../../directives/accordion.directive';
import { PlatformService } from '../../../services/platform.service';
import { TranslationService } from '../../translation/translation.service';
import {SeoService} from '../../../services/seo.service';
import {ReplaySubject} from 'rxjs';
import {TournamentsService} from '../../../../page/tournaments/tournaments.service';
import {ZendeskChatService} from '../../../services/zendesk/zendesk-chat.service';
import {WrSocketService} from '../../../services/wr-socket.service';
import {JackpotService} from '../../../../page/jackpot/jackpot.service';
import { TranslatePipe } from '../../translation/translate.pipe';
import { IgnoreHtmlTagsPipe } from '../../pipes/ignore-html-tags.pipe';
import { LottieComponent } from '../../../standalone/lottie/lottie.component';
import { RouterLinkDirective } from '../../directives/router-link.directive';
import { NgClass, AsyncPipe } from '@angular/common';
import { ClickOutsideDirective } from '../../directives/click-outside.directive';
import {AB_TEST_LIST} from '../../../ab-test/ab-test.data';
import {HalloweenService} from '../../../../page/promo/halloween/halloween.service';
import {SafePipe} from 'ngx-unificator/pipes';
import {InstallAppService} from '../../../services/install-app.service';

@Component({
    selector: 'app-side-nav',
    templateUrl: './side-nav.component.html',
    styleUrls: ['./side-nav.component.scss'],
    standalone: true,
  imports: [ClickOutsideDirective, NgClass, RouterLink, RouterLinkDirective, RouterLinkActive, LottieComponent, AccordionDirective, AsyncPipe, IgnoreHtmlTagsPipe, TranslatePipe, SafePipe]
})
export class SideNavComponent implements OnInit {

  @ViewChild('accordion', {read: AccordionDirective}) accordion: AccordionDirective;

  public isSettingsOpened: boolean;

  /**
   * Tournament list from CMS
   */
  public tournamentList$: ReplaySubject<any> = this._tournaments.list();

  protected readonly AB_TEST_LIST = AB_TEST_LIST;

  constructor(
    private _router: Router,
    private _language: LanguageService,
    private _platform: PlatformService,
    private _translationService: TranslationService,
    private _tournaments: TournamentsService,
    public sidenav: SideNavService,
    public env: EnvironmentService,
    public seo: SeoService,
    public user: UserService,
    public zendesk: ZendeskChatService,
    private _wrSocket: WrSocketService,
    public jackpot: JackpotService,
    public halloween: HalloweenService,
    public installApp: InstallAppService
  ) {
  }

  get auth(): boolean {
    return this.user.auth;
  }

  get userInfo(): any {
    return this.user.info;
  }

  get LANG(): string {
    return this._language.current;
  }

  ngOnInit() {
  }

  /**
   * Change UI language
   *
   * @param lang
   */
  changeLanguage(lang: string) {
    if (this._platform.isBrowser) {

      const url = this._router.url.replace(`/${this.LANG}`, `/${lang}`);

      this.sidenav.close();
      this.accordion.close();

      this._router.navigateByUrl(`/${url}`)
        .then(() => {
          this._translationService.getUserTranslates$().subscribe();
        });
    }
  }

  handleClickForScroll() {
    if (this._platform.isBrowser) {
      document.getElementById('sidebar-content')
        .scroll({top: 500, behavior: 'smooth'});
    }
  }

  logout() {
    this._wrSocket.sendEventLogout();
    this.user.logout();
  }

  closeMenu() {
    this.sidenav.close();
  }

  onPlayButtonClick(): void {
    this._router.navigate(['/', this.LANG, 'games']);
  }

  onDepositButtonClick(): void {
    this._router.navigate(['/' + this.LANG, 'account', 'deposit']);
  }

  onSettingsClick(): void {
    this.isSettingsOpened = !this.isSettingsOpened;
    if (this.isSettingsOpened && this._platform.isBrowser) {
      setTimeout(() => {
        const settingsBlock = document.getElementById('settings-block');
        settingsBlock.scrollIntoView({behavior: 'smooth'});
      }, 100);
    }
  }
}
