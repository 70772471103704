import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ArrayService {

  constructor() {
  }

  /**
   * Split array to chunks
   *
   * Example:
   *  toChunks([1, 2, 3, 4, 5, 6, 7], 3)
   *
   * Output:
   *  [[1, 2, 3], [4, 5, 6], [7]]
   *
   * @param original
   * @param chunkSize
   */
  toChunks(original: Array<any>, chunkSize: number) {
    const result = [];

    for (let i = 0; i < original?.length; i += chunkSize) {
      result.push(original.slice(i, i + chunkSize));
    }

    return result;
  }

  /**
   * Returns array of url segments from string
   *
   * @param route
   * @private
   */
  public routeToArray(route: string): Array<string> {
    const url = route.split('?')[0];

    return (url.split('/')).splice(1);
  }

  /**
   * Get unique elements by key
   *
   * @param {string} key
   * @param {any[]} arr
   * @returns {any}
   */
  uniqueElements(key: string, arr: any[]) {
    const sort = arr.reduce((acc, cur) => {

      /**
       * Temporary condition for deposit bonus need improve in future
       */
      if (cur.bonus) {
        acc[cur.bonus[key]] = {...acc[cur.bonus[key]], ...cur};
      } else {
        acc[cur[key]] = {...acc[cur[key]], ...cur};
      }
      return acc;
    }, {});

    return Object.values(sort);
  }

  /**
   * Returns array from object values
   *
   * Example:
   *  fromObjectValues({
   *    error: {
   *      amount: 'not enough funds'
   *    }
   *  })
   *
   * Output:
   *  ['not enough funds']
   *
   * @param object
   */
  fromObjectValues(object: object): any[] {
    const list = [];

    Object.values(object).forEach(
      value => typeof value === 'object'
        ? list.push(...this.fromObjectValues(value))
        : list.push(value)
    );

    return list;
  }

  /**
   * Cycle array items
   *
   * @param {any[]} originalArray
   * @param {number} count
   * @returns {any[]}
   * @private
   */
  cycle(originalArray: any[], count: number) {

    const newArray = [];

    if (!originalArray || !originalArray.length) {
      return newArray;
    }

    while (newArray.length < count) {
      for (const arr of originalArray) {
        if (newArray.length < count) {
          newArray.push(arr);
        }
      }
    }

    return newArray;
  }
  /**
   * Shuffle array
   * @param array
   */
  shuffle(array: any[]) {
    let currentIndex = array.length;
    let temporaryValue;
    let randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  /**
   * Check if array has min length of game and clone it
   * @param gameList
   * @param slideCount
   * @private
   */
  public checkAndCloneArray(gameList: any, slideCount: number) {

    let cloneArr = [];

    let arrayToChunks = this.toChunks(gameList, slideCount);

    if (gameList?.length % slideCount === 0) {
      cloneArr = gameList;
    } else {
      cloneArr = this.cycle(gameList, gameList?.length + slideCount - arrayToChunks[arrayToChunks?.length - 1]?.length);
    }

    return cloneArr;
  }

  /**
   * Remove same object by field
   * @param arr
   * @param field
   */
  public removeSameObjectByField(arr: any[], field: string): any[] {
    const newArr = [];
    arr.forEach(item => !newArr.some(itemTwo => itemTwo[field] === (item[field])) ? newArr.push(item) : null);
    return newArr;
  }
}
