import {Component, EventEmitter, Input, OnDestroy, OnInit} from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { StaticContentService } from '../../../services/static-content.service';
import { GameProvider, GameProvidersService } from '../../../services/game-providers.service';
import { BankingService } from '../../../services/banking.service';
import { UserService } from '../../../services/user/user.service';
import {filter, first, map, takeUntil} from 'rxjs/operators';
import { PlatformService } from '../../../services/platform.service';
import { WindowService } from '../../../services/window.service';
import {ScriptLoaderService} from '../../../services/script-loader.service';
import {LanguageService} from '../../../services/language/language.service';
import {UserInfoService} from '../../../services/user/user-info.service';
import {GlobalEventsService} from '../../../services/global-events.service';
import {BreakpointsService} from '../../../services/breakpoints.service';
import { SeoService } from '../../../services/seo.service';
import { TranslatePipe } from '../../translation/translate.pipe';
import { SafePipe } from '../../pipes/safe.pipe';
import { ImgDimensionDirective } from '../../directives/img-dimension.directive';
import { ClassLoadedDirective } from '../../directives/class-loaded.directive';
import { DeleteNotFoundDirective } from '../../directives/delete-not-found.directive';
import { RouterLinkDirective } from '../../directives/router-link.directive';
import { RouterLink } from '@angular/router';
import { NgClass, AsyncPipe } from '@angular/common';
import {AccordionButtonComponent} from '../../components/accordion-button/accordion-button.component';

const biggerProvidersLogo: string[] = [
  'thunderkick', 'pragmaticplaylive', 'relax',
  'yggdrasil', 'pragmaticplay', 'electricelephant',
  'bigtimegaming', 'tomhorn', 'kagaming', 'avatarux',
  'mascot', 'mrslotty', 'zillion', 'luckystreak', 'bangbanggames',
  'retrogaming', 'bulletproof', 'bluegurugames', 'reevo',
  'evoplay', 'jelly', 'mancala', 'netgame', 'nolimit'
];

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: true,
  imports: [RouterLink, RouterLinkDirective, DeleteNotFoundDirective, NgClass, ClassLoadedDirective, ImgDimensionDirective, AsyncPipe, SafePipe, TranslatePipe, AccordionButtonComponent]
})
export class FooterComponent implements OnInit, OnDestroy {

  /**
   * Pass true if you want hide license text and logos
   */
  @Input('hideText') hideText: boolean;

  /**
   * Copyright text from CMS
   */
  public copyright$: ReplaySubject<any> = this._staticContent.item({slug: 'footer-copyright'});

  /**
   * List of game providers
   */
  public providerList$: ReplaySubject<GameProvider[]> = this._gameProviders.list$.pipe(
    map(list => {
      return list.map(provider => ({
        ...provider,
        biggerThanOther: biggerProvidersLogo.some(alias => provider.alias === alias)
      })).filter(e => e.inFooter);
    })
  ) as ReplaySubject<GameProvider[]>;

  /**
   * List of payment methods from CMS
   */
  public paymentList$: Observable<any> = this._banking.cmsPaymentMethods.pipe(
    map(list => list.map(item => {
      return {
        ...item,
        img: this._resolveImg(item)
      };
    })),
  );

  public stickyVisible: boolean = null;

  /**
   * Completes when service destroyed
   */
  private _alive$: EventEmitter<boolean> = new EventEmitter<boolean>();

  public license$: Observable<{Img: string; Url: string;}> = this._staticContent.item({ slug: 'wf-license-static' }).pipe(
    filter(data => !!data?.[0]),
    first(),
    map(data => data?.[0]),
  );

  constructor(
    public seo: SeoService,
    public user: UserService,
    public lang: LanguageService,
    public userInfo: UserInfoService,
    public breakpoints: BreakpointsService,
    private _staticContent: StaticContentService,
    private _gameProviders: GameProvidersService,
    private _banking: BankingService,
    private _platform: PlatformService,
    private _window: WindowService,
    private _scriptLoader: ScriptLoaderService,
    private _events: GlobalEventsService
  ) {
    if(this._platform.isBrowser) {
      this._scriptLoader.load('https://images.dmca.com/Badges/DMCABadgeHelper.min.js', undefined, {async: true}).subscribe();
    }
  }

  ngOnInit() {
    this._handleScroll();
  }

  /**
   * Window scroll handler
   * @private
   */
  private _handleScroll() {
    if (this._platform.isBrowser) {
      this._events.scroll$.pipe(
        takeUntil(this._alive$),
      ).subscribe(() => {
        if (!this.stickyVisible) {
          this.stickyVisible = this._window.nativeWindow.pageYOffset > 100;
        }
      });
    }
  }

  ngOnDestroy() {
    this._alive$.next(false);
    this._alive$.complete();
  }

  public _resolveImg(item) {
    switch (true) {
      case item.slug.includes('interac'):
        return 'https://cdn2.softswiss.net/wildfortune/logos/payments/white/interac_white.svg';
      default:
        return 'https://cdn.softswiss.net//logos/payments/white/' + item.slug + '.svg';
    }
  }
}
